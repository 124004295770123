import { isIOS } from '@croquiscom/web2app';
import dayjs from 'dayjs';
import isUndefined from 'lodash-es/isUndefined';
import omitBy from 'lodash-es/omitBy';
import { Toast } from './Toast';
import config from 'util/config';

interface LegacyPageHeader {
  headerTitle?: {
    type: string;
    text?: string;
    onClick?: string;
  };
  headerHidden?: boolean;
  headerLefts?: [];
  headerRights?: [];
}

interface Profile {
  uuid: string;
  email: string;
  name?: string | null;
}

export interface FbkAppInterface {
  setTitle({ title }: { title: string }): void;
  setPage(props: LegacyPageHeader): void;
  updateLoginStatus(props: { logged_in: boolean }): void;
  updateProfile(json_stringifyed_profile: string): void;
  loginWithKakao(props: { authCallbackUrl: string; redirectUrl: string }): void;
  subl(props: { log: Record<string, any> }): void;
  logEvent(props: { name: string; json_stringifyed_parameters: string }): void;
  back(): void;
  close(): void;
  toast(props: { message: string }): void;
  can(): void;
  updateCart(): void;
  vibrateWithDuration(props: { duration: string }): void;
  showLoginPopup(props: { script: string }): void;
  showAppReviewPopup(): void;
  playLiveCommerce(props: { campaignKey: string }): void;
  stopLiveCommerce(): void;
  logout(): void;
}

interface KakaoMktInterface {
  logFacebook(event_name: string, json_stringifyed_parameters: string): void;
  logFirebase(event_name: string, json_stringifyed_parameters: string): void;
  logBraze(event_name: string, json_stringifyed_parameters: string): void;
}
interface FbkHandler {
  onAppNotificationStatus?(noti_status: string): void;
  finishSplash?(): void;
}

declare global {
  interface Window {
    'fbk'?: FbkAppInterface;
    'kakaostyle-mkt'?: KakaoMktInterface;
    // iOS
    'webkit'?: {
      messageHandlers?: {
        'fbk'?: {
          postMessage?: (message: Record<string, any>) => void;
        };
        'kakaostyle-mkt'?: {
          postMessage?: (message: Record<string, any>) => void;
        };
      };
    };

    // callback
    'fbkHandler'?: FbkHandler;
  }
}

export enum MAIN_TAB_ID {
  HOME = '1',
  CATEGORIES = '5',
  STORE = '6',
  LIKE = '7',
  MY_PAGE = '8',
}

export enum SUB_TAB_ID {
  HOME_TREND = '2',
  HOME_BEST = '3',
  HOME_SALE = '4',
  HOME_NEW = '10',
}

export interface AppBottomSheetInfo {
  type: 'none' | 'image' | 'icon';
  title?: string;
  message?: string;
  image_url?: string;
  ok: {
    title: string;
    type: string;
  };
  cancel: {
    title: string;
    type: string;
  };
}

export interface AppAlertInfo extends Omit<AppBottomSheetInfo, 'cancel'> {}

type ArgumentTypes<F extends Function> = F extends (args: infer A) => any ? A : never;

export function callAppInterface<T extends keyof FbkAppInterface>(
  app_func_name: T,
  args?: ArgumentTypes<FbkAppInterface[T]>,
) {
  if (!isExistAppInterface()) {
    console.warn('앱에서 실행할 수 있습니다.');
    return;
  }

  if (typeof window === 'undefined') {
    return;
  }

  try {
    const aos_interface = window.fbk;

    if (aos_interface) {
      const getAosParams = () => {
        if (!args) {
          return [];
        }

        if (app_func_name === 'subl') {
          return [JSON.stringify(Object.values(args)[0])];
        }

        if (app_func_name === 'setPage' || app_func_name === 'updateProfile') {
          return [JSON.stringify(args)];
        }

        const is_single_obj_param = typeof args === 'object' && Object.keys(args).length === 1;

        if (is_single_obj_param) {
          return [JSON.stringify(args)];
        }

        return Object.values(args);
      };
      const params = getAosParams();
      // @ts-ignore
      aos_interface[app_func_name](...params);
    }

    if (window.webkit?.messageHandlers?.fbk?.postMessage) {
      const ios_props = omitBy(
        {
          name: app_func_name,
          parameters: args ?? undefined,
        },
        isUndefined,
      );

      window.webkit.messageHandlers.fbk.postMessage(ios_props);
      return;
    }
  } catch (error) {
    if (!(error instanceof Error)) {
      return;
    }
    console.error(`[${app_func_name} error]`, error.message);
  }
}

export function callMktInterface<T extends keyof KakaoMktInterface>(
  function_name: T,
  event_name: string,
  parameters: Record<string, any>,
) {
  const MKT_MSG = 'kakaostyle-mkt';

  try {
    if (window[MKT_MSG]) {
      window[MKT_MSG][function_name](event_name, JSON.stringify(parameters));
    }

    if (window.webkit?.messageHandlers?.[MKT_MSG]?.postMessage) {
      window.webkit.messageHandlers[MKT_MSG].postMessage({
        function_name,
        event_name,
        parameters,
      });
    }
  } catch (error) {
    if (!(error instanceof Error)) {
      return;
    }
    console.warn('[mkt-interface error]', error.message);
  }

  console.warn(`not exist mkt function: ${function_name}`);
}

/**
 * 예전버전 헤더를 제거한다.
 */
export function hideHeaderForLegacyApp() {
  const props: LegacyPageHeader = {
    headerHidden: true,
    headerTitle: {
      text: '',
      type: 'none',
      onClick: undefined,
    },
    headerLefts: [],
    headerRights: [],
  };
  callAppInterface('setPage', props);
}

/**
 * ubl 전송
 */
export function subl(log: Record<string, any>) {
  if (typeof window !== 'undefined') {
    try {
      if (window.fbk) {
        // 로그 긴급대응
        //@ts-ignore
        window.fbk.subl(JSON.stringify(log));
      } else if (window.webkit?.messageHandlers?.fbk?.postMessage) {
        window.webkit.messageHandlers.fbk.postMessage({
          name: 'subl',
          parameters: log,
        });
      }
    } catch (error) {
      if (!(error instanceof Error)) {
        return;
      }
      console.log('[subl error]', error.message);
    }
  }
}

/**
 * 로그인, 로그아웃 시점 앱에 프로필 정보 업데이트 용도
 * MEMO: callAppInterface 함수에서 로그아웃의 경우 null 값으로 전달하는데 이에대한 예외처리가 되지 않아 updateProfile 인터페이스를 호출하지 못하여 직접 호출로 변경. 추후 변경 필요합니다.
 * https://croquis.slack.com/archives/C02879ETRSR/p1694587657882649
 */
export function updateProfile(profile: Profile | null) {
  if (typeof window !== 'undefined') {
    try {
      if (window.fbk) {
        window.fbk.updateProfile(JSON.stringify(profile));
      } else if (window.webkit?.messageHandlers?.fbk?.postMessage) {
        window.webkit.messageHandlers.fbk.postMessage({
          name: 'updateProfile',
          parameters: profile,
        });
      }
    } catch (error) {
      if (!(error instanceof Error)) {
        return;
      }
      console.log('[updateProfile error]', error.message);
    }
  }
}

/**
 * 앱 제공 카카오 로그인 테스트 검토
 */
export function kakakoLoginForApp(redirect_url: string, callback_url?: string) {
  const auth_callback_url = callback_url ?? `${window.location.origin}/auth/kakao-callback-app`;
  callAppInterface('loginWithKakao', { authCallbackUrl: auth_callback_url, redirectUrl: redirect_url });
}

/**
 * 현재 액티비티 웹뷰를 바로 닫는다.
 */
export function close() {
  callAppInterface('close');
}

/**
 * 앱에서 로그아웃시 사용한다.
 */
export function logout() {
  callAppInterface('logout');
}

/**
 * WebView 히스토리 존재 시 history back 수행. 히스토리 미존재 시 현재 Activity/Controller 종료
 */
export function back() {
  callAppInterface('back');
}

/**
 * 샵라이브 방송 시작
 */
export function playShopLiveCampaign(campaignKey: string) {
  callAppInterface('playLiveCommerce', { campaignKey });
}

/**
 * 샵라이브 방송 종료
 */
export function StopShopLiveCampaign() {
  callAppInterface('stopLiveCommerce');
}

/**
 * OS에서 제공하는, 앱 리뷰 팝업 띄우기
 */
export function showAppReviewPopup() {
  callAppInterface('showAppReviewPopup');
}

/**
 * 로그인 네이티브 바텀시트 노출
 */
export function showLoginPopup(redirect_url: string) {
  const is_ios = isIOS();

  const login_script = is_ios
    ? `window.webkit.messageHandlers.fbk.postMessage({
name: 'loginWithKakao',
parameters: {
  authCallbackUrl: "${window.location.origin}/auth/kakao-callback-app",
  redirectUrl: "${window.location.origin}${redirect_url}",
},
});`
    : `window.fbk.loginWithKakao("${window.location.origin}/auth/kakao-callback-app", "${redirect_url}");`;

  const script = redirect_url === '' ? '' : login_script;
  console.log('script', script);

  callAppInterface('showLoginPopup', { script });
}

//evawindow.fbk = { loginWithKakao: (a,b,) => console.log(a,b)};

/**
 * 진동 액션 인터페이스
 */
export function vibrateWithDuration(duration_ms: number) {
  callAppInterface('vibrateWithDuration', { duration: duration_ms.toString() });
}

/**
 * 앱에서 장바구니 갯수 갱신 필요시 호출하여 싱크
 */
export function updateCart() {
  callAppInterface('updateCart');
}

export function logFacebook(event_name: string, parameters: Record<string, any>) {
  callMktInterface('logFacebook', event_name, parameters);
}

export function logFirebase(event_name: string, parameters: Record<string, any>) {
  callMktInterface('logFirebase', event_name, parameters);
}

export function logBraze(event_name: string, parameters: Record<string, any>) {
  callMktInterface('logBraze', event_name, parameters);
}

// App에서 OS의 알림 허용 상태 체크하여 agree, disagree 중에 값 전달
export function checkAppNotificationStatus(callback: (noti_status: string) => void) {
  if (typeof window !== 'undefined') {
    try {
      if (!window.fbkHandler) {
        window.fbkHandler = {};
      }

      window.fbkHandler.onAppNotificationStatus = (noti_status: string) => {
        callback(noti_status);
        delete window.fbkHandler?.onAppNotificationStatus;
      };

      if (window.fbk?.can) {
        window.fbk.can();
      } else if (window.webkit?.messageHandlers?.fbk?.postMessage) {
        window.webkit.messageHandlers.fbk.postMessage({
          name: 'can',
        });
      }
    } catch (error) {
      if (!(error instanceof Error)) {
        return;
      }
      console.error('[can error]', error.message);
    }
  }
}

// 스플래시 종료 이벤트
export function finishSplash(callback: () => void) {
  if (typeof window !== 'undefined') {
    try {
      if (!window.fbkHandler) {
        window.fbkHandler = {};
      }

      window.fbkHandler.finishSplash = () => {
        callback();
        delete window.fbkHandler?.finishSplash;
      };
    } catch (error) {
      if (!(error instanceof Error)) {
        return;
      }
      console.error('[finishsplash] error]', error.message);
    }
  }
}

export function isSupportAppHandler(handler_name: keyof FbkHandler) {
  if (typeof window === 'undefined') {
    return false;
  }
  return window.fbkHandler && typeof window.fbkHandler[handler_name] === 'function';
}

export function openAppSettings() {
  window.location.href = `${config.app_scheme}://open/notification/app_settings`;
}

/**
 * OneLink를 생성합니다.
 * @param route_path - 이동할 경로를 작성합니다.
 */
export function makeOneLink(route_path: string) {
  const app_link = getAppDeepLink(route_path, 'full');
  const web_link = route_path.startsWith('http') ? route_path : `${config.appUrl}${route_path}`;

  const deep_link = `${config.airbridge_url}/${config.airbridge_share_channel}?fallback_desktop=${encodeURIComponent(
    web_link,
  )}&deeplink_url=${encodeURIComponent(app_link)}`;

  return deep_link;
}

export enum APP_VERSION_TYPE {
  // NATIVE 전환 버전
  NATIVE_SUPPORT = '6.4.9',
  // NATIVE에서 메인, 서브탭의 preview 로그를 보내는 버전
  NATIVE_SEND_PAGEVIEW_LOG = '6.5.9',
  // 라이브 방송 지원 버전
  LIVE_SUPPORT = '6.8.9',
  // 로그인 네이티브
  LOGIN_NATIVE = '6.16.9',
}

/**
 * @description
 * 비교할 버전보다 현재 앱의 버전이 낮은지 검사할때 사용합니다.
 * @example
 * const is_low_version = isLowAppVersion('6.4.1', 'blabla FBK/ 6.4.0');
 * is_low_version // true
 */
export function isLowAppVersion(compare_version: string, user_agent: string) {
  const splitVersion = (version: string) => version.split('.').map((v) => Number(v));
  const current_version = splitVersion(getAppVersion(user_agent));
  const cocompare_version = splitVersion(compare_version);

  if (current_version.length !== cocompare_version.length) {
    return false;
  }

  for (let i = 0; i < cocompare_version.length; i++) {
    if (current_version[i] === cocompare_version[i]) {
      continue;
    }

    return current_version[i] > cocompare_version[i];
  }
  return false;
}

/**
 * @description
 * 현재 앱버전을 반환합니다. (semver)
 */
export function getAppVersion(user_agent: string) {
  const exp_match = user_agent.match(/FBK\/([0-9.]*)/);
  if (exp_match) {
    return exp_match[0].split('/')[1];
  }

  return '';
}

export type WebViewType = 'navigation' | 'full';

/**
 * @description
 * 앱 딥링크를 조회합니다.
 */
export const getAppDeepLink = (url: string, type: WebViewType | undefined) => {
  try {
    const browser_url = url.startsWith('http') ? url : `${config.appUrl}${url}`;
    const browser_type = type ?? 'full';

    // NOTE: url 쿼리스트링에 한글이 들어오는 경우, IOS에서 정상 동작하지 않아 두 번 인코딩
    return `${config.app_scheme}://open/browser?browser_type=${browser_type}&url=${encodeURIComponent(
      encodeURI(browser_url),
    )}`;
  } catch {
    Toast.show('다시 시도해 주세요.');
  }
  return '';
};

/**
 * @description
 * 앱 딥링크 함수를 호출 합니다.
 */
export const callAppDeepLink = (url: string, type: WebViewType | undefined) => {
  if (typeof window === 'undefined') {
    return;
  }

  window.location.href = getAppDeepLink(url, type);
};

export function callBottomSheet(props: AppBottomSheetInfo) {
  if (!isExistAppInterface()) {
    console.warn('앱에서 실행할 수 있습니다.');
    return;
  }

  return window.confirm(JSON.stringify(omitBy(props, isUndefined)));
}

export function callAlert(props: AppAlertInfo) {
  if (!isExistAppInterface()) {
    console.warn('앱에서 실행할 수 있습니다.');
    return;
  }

  window.alert(JSON.stringify(omitBy(props, isUndefined)));
}

export function isExistAppInterface() {
  if (typeof window === 'undefined') {
    return false;
  }
  const aos_interface = window.fbk;
  const ios_interface = window.webkit?.messageHandlers?.fbk?.postMessage;

  return aos_interface || ios_interface;
}

/**
 * @description
 * 메인 탭 영역으로 바로 이동하고 싶은 경우 호출합니다.
 */
export function moveMainTab({ main_tab, sub_tab }: { main_tab: MAIN_TAB_ID; sub_tab?: SUB_TAB_ID }) {
  const main_tab_path = `main_tab_id=${main_tab}`;
  const tab_link = sub_tab ? `${main_tab_path}&sub_tab_id=${sub_tab}` : main_tab_path;
  window.location.href = `${config.app_scheme}://open/main?${tab_link}`;
}

export function moveAppUpdateMarketLink(os_type: 'aos' | 'ios') {
  return os_type === 'ios' ? 'itms-apps://itunes.apple.com/app/id653403166' : 'market://details?id=com.kakao.style';
}

/**
 *
 * @description
 * url로 메인 탭의 서브탭 ID를 알고싶은 경우 호출합니다.
 */
export function getHomeSubTabId(url: string) {
  const main_sub_tab_value = url.split('/?tab=')[1];
  const home_sub_tab_id = {
    hot: SUB_TAB_ID.HOME_TREND,
    best: SUB_TAB_ID.HOME_BEST,
    sale: SUB_TAB_ID.HOME_SALE,
    new: SUB_TAB_ID.HOME_NEW,
  }[main_sub_tab_value];

  return home_sub_tab_id;
}

/**
 *
 * @description
 * 24. 05 .01
 * 패바카 서비스 종료 1차 노티
 */
export function isServiceCloseNotify() {
  const close_notify_date = dayjs('2024-05-01');
  return dayjs().isAfter(close_notify_date);
}
